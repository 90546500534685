export const times = [
  {
    date: '2025-01-07',
    label: '07.Jan.Tue',
    hours: ['10AM', '11AM', '12AM', '1PM', '2PM', '3PM', '4PM', '5PM'],
    startTimes: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30'],
    endTimes: ['10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', ],
  },
  {
    date: '2025-01-08',
    label: '08.Jan.Wed',
    hours: ['09AM', '10AM', '11AM', '12AM', '1PM', '2PM', '3PM', '4PM', '5PM'],
    startTimes: ['09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30'],
    endTimes: ['09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', ],
  },
  {
    date: '2025-01-09',
    label: '09.Jan.Thu',
    hours: ['09AM', '10AM', '11AM', '12AM', '1PM', '2PM', '3PM', '4PM', '5PM'],
    startTimes: ['09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30'],
    endTimes: ['09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', ],
  },
  {
    date: '2025-01-10',
    label: '10.Jan.Fri',
    hours: ['09AM', '10AM', '11AM', '12AM', '1PM', '2PM', '3PM'],
    startTimes: ['09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30'],
    endTimes: ['09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00'],
  }
];

export const rooms = [
  { room: 'A1', name: 'A1', capacity: 9, secret: false, shape: 'rect', coords: [569,42,510,134], map: 'room-1' },
  { room: 'A2', name: 'A2', capacity: 4, secret: false, shape: 'rect', coords: [570,184,510,242], map: 'room-1' },
  { room: 'A3', name: 'A3', capacity: 4, secret: false, shape: 'rect', coords: [463,221,406,281], map: 'room-1' },
  { room: 'A4', name: 'A4', capacity: 6, secret: false, shape: 'rect', coords: [406,222,320,279], map: 'room-1' },
  { room: 'A5', name: 'A5', capacity: 6, secret: false, shape: 'rect', coords: [244,222,318,279], map: 'room-1' },
  { room: 'A6', name: 'A6', capacity: 8, secret: false, shape: 'rect', coords: [195,207,95,272], map: 'room-1' },
  { room: 'A7', name: 'A7', capacity: 6, secret: false, shape: 'rect', coords: [94,206,15,273], map: 'room-1' },
  { room: 'A8A9', name: 'A8A9', capacity: 20, secret: false, shape: 'rect', coords: [243,152,461,211], map: 'room-1' },
  { room: 'A10', name: 'A10', capacity: 8, secret: false, shape: 'rect', coords: [194,141,95,206], map: 'room-1' },
  { room: 'A11', name: 'A11', capacity: 4, secret: false, shape: 'rect', coords: [15,141,95,206], map: 'room-1' },
  { room: 'A12', name: 'A12', capacity: 6, secret: false, shape: 'rect', coords: [473,42,397,102], map: 'room-1' },
  { room: 'A13', name: 'A13', capacity: 6, secret: false, shape: 'rect', coords: [395,44,320,100], map: 'room-1' },
  { room: 'A14', name: 'A14', capacity: 6, secret: false, shape: 'rect', coords: [318,101,244,42], map: 'room-1' },
  { room: 'A15', name: 'A15', capacity: 8, secret: true, shape: 'rect', coords: [243,43,150,102], map: 'room-1' },
  { room: 'A16', name: 'A16', capacity: 8, secret: true, shape: 'rect', coords: [59,43,149,102], map: 'room-1' }
];