import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { logout } from "@/actions/auth";
import history from '@/helpers/history';

function Header(props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user);

  //  const [isNavOpen, setIsNavOpen] = useState(['/admin/tour','/admin/tour-calendar'].indexOf(history.location.pathname.toLowerCase()) > -1);

  // const handleNavToggle = () => {
  //   setIsNavOpen(!isNavOpen);
  // };

  return (
    <>
      { 
        currentUser.isLoggedIn && currentUser.user.role === 'Admin' && (
          <div id="nav_wrap">
            <div className="nav_wrap-top">
              <span className="user_id">{currentUser?.user.firstName} {currentUser?.user?.lastName}</span>
              <button className="btn-log" onClick={()=>dispatch(logout())}>LOGOUT</button>
            </div>
            <div className="nav_wrap-menus">
              {currentUser?.isLoggedIn && currentUser.user.permissions.includes('UserManager') && (<div className="nav_wrap-menu">
                <NavLink to={'/admin/member'}>Member</NavLink>
              </div>)}

              {currentUser?.isLoggedIn && currentUser.user.permissions.includes('RoomManager') && (<div className="nav_wrap-menu">
                <NavLink to={'/admin/room'}>Business Lounge</NavLink>
              </div>)}
 
              {currentUser?.isLoggedIn && currentUser.user.permissions.includes('VipManager') && (<div className='nav-wrap-2dep'>
                  <div className="nav_wrap-menu">
                    <NavLink to={'/admin/tour'} className={() => ['/admin/tour','/admin/tour-calendar'].indexOf(history.location.pathname.toLowerCase()) > -1 ? 'active' : 'inactive'}>VIP Tour</NavLink>
                  </div>
                  <div className="nav_wrap-menu nav-wrap-menu2">
                    <NavLink to={'/admin/tour'}><span className='nav_wrap-menu-bar'></span>List</NavLink>
                  </div>
                  <div className="nav_wrap-menu nav-wrap-menu2">
                    <NavLink to={'/admin/tour-calendar'}><span className='nav_wrap-menu-bar'></span>Calendar</NavLink>
                  </div>
              </div>)}
            </div>
          </div>
        )
      }
    </>
  );
}

export default Header;
