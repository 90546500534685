import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { cancelReservation, tours, tour } from '@/actions/tour';
import { setIsLoading } from '@/actions/common';
import _, { set } from 'lodash';
import { toast } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import { MultiSelect } from "react-multi-select-component";
import resetIcon from '@/assets/images/icon_refresh.png';
import editIcon from '@/assets/images/icon_edit.png';
import { updateReservation, updateReservationLabel, updateReservationMemo, updateReservationPersonInCharge} from '@/actions/tour';
import { FormattedMessage } from 'react-intl';
import useDynamicRefs from 'use-dynamic-refs';
import tourTimeData from '@/data/tourTime';

import { allReservations, approveReservation, vvipReservation, inProgressReservation, declineReservation, sendMailReservationStatus, deleteManyReservation, downloadExcel } from '@/actions/tour';

export default function AdminTour() {
  const dispatch = useDispatch();
  const [getRef, setRef] =  useDynamicRefs();

  const searchParams = new URLSearchParams(useLocation().search);

  const options = [
    { value: 'In Progress', label: 'In Progress' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Canceled', label: 'Canceled' },
    { value: 'Declined', label: 'Declined' }
  ]

  const [checkList, setCheckList] = useState([]);
  const [idList, setIdList] = useState([]);
  const [errorSelectIdList, setErrorSelectIdList] = useState([]);
  const [isNotSetPersionInCharge, setIsNotSetPersionInCharge] = useState(false);
  const [openLabelBoxId, setOpenLabelBoxId] = useState(null);
  const [currentItems, setCurrentItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(searchParams.get('page') || 1);
  const [pageLimit, setPageLimit] = useState(searchParams.get('limit') || 20);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [isReservationView, setIsReservationView] = useState(false);
  const [isReservationCancel, setIsReservationCancel] = useState(false);
  
  const [searchId, setSearchId] = useState(searchParams.get('searchId') || '');
  const [searchDate, setSearchDate] = useState(searchParams.get('date') || '');
  const [searchTime, setSearchTime] = useState(searchParams.get('time') || '');
  const [searchStatus, setSearchStatus] = useState(searchParams.get('status')?_.filter(options, r=>searchParams.get('status').trim().split(',').includes(r.value)):[]);
  // const [searchStatus, setSearchStatus] = useState(searchParams.get('status'));
  const [searchLanguage, setSearchLanguage] = useState(searchParams.get('language') || '');
  const [searchHostText, setSearchHostText] = useState(searchParams.get('host') || '');
  const [searchGuestText, setSearchGuestText] = useState(searchParams.get('guest') || '');
  const [searchVVIP, setSearchVVIP] = useState(searchParams.get('vvip') ? searchParams.get('vvip') === 'true' : false);
  const [searchReset, setSearchReset] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const componentRef = useRef(null);

  const [isReservationRefusalModal, setIsReservationRefusalModal] = useState(false);
  const [isReservationApprovalModal, setIsReservationApprovalModal] = useState(false);
  const [isReservationPriorityModal, setIsReservationPriorityModal] = useState(false);
  const [isSendMailReservationInfoModal, setIsSendMailReservationInfoModal] = useState(false);
  const [isReservationInProgressModal, setIsReservationInProgressModal] = useState(false);
  const [isReservationDeleteModal, setIsReservationDeleteModal] = useState(false);

  const { basePath } = useSelector((state) => state.common);

  useEffect(() => {
    getReservations()
  }, [currentPage, pageLimit]);

  useEffect(() => {
    if(searchReset){
      getReservations()
    }
  }, [searchReset]);

  useEffect(() => {
    function handleClickOutside(e) {
      if (getRef(`labelBox_${openLabelBoxId}`) && !getRef(`labelBox_${openLabelBoxId}`).current.contains(e.target)) {
        setOpenLabelBoxId(null)
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [ openLabelBoxId ]);

  const getReservations = () => {
    if(searchReset){
      setSearchReset(false);
    }
    dispatch(setIsLoading(true));
    dispatch(allReservations({
      page: currentPage,
      limit: pageLimit,
      searchId,
      searchDate: searchDate ? moment(searchDate).format('YYYY-MM-DD') : '',
      searchTime: searchTime,
      searchStatus: searchStatus.map(item=>item.value).join(','),
      // searchStatus: searchStatus,
      searchVVIP,
      searchLanguage,
      searchHostText,
      searchGuestText,
    }))
    .then(res => {
      setIdList(res.docs.map(item=>item.id))
      setTotalPageCount(res.totalPages);
      setTotalItemCount(res.totalDocs);
      setCurrentItems(res.docs.map(item=>{return {...item, label: {
        ...item.label,
        tempText: item?.label?.text
      }, tempMemo: item.memo}}));
      dispatch(setIsLoading(false));
      setCheckList([]);
    })
  }

  useEffect(() => {
    getReservations();
  }, [searchVVIP]);

  const onDownloadExcel = () => {
    dispatch(downloadExcel({
      searchId,
      searchDate: searchDate ? moment(searchDate).format('YYYY-MM-DD') : '',
      searchTime: searchTime,
      searchStatus: searchStatus.map(item=>item.value).join(','),
      // searchStatus: searchStatus,
      searchVVIP,
      searchLanguage,
      searchHostText,
      searchGuestText,
    })) 
  }

  useEffect(() => {
    handleSearch();
 },[searchDate, searchTime, searchStatus, searchLanguage])

  const handleSearch = () => {
    const url = new URL(window.location);
    url.searchParams.set('searchId', searchId);
    url.searchParams.set('language', searchLanguage);
    url.searchParams.set('date', searchDate);
    url.searchParams.set('time', searchTime);
    url.searchParams.set('status', searchStatus.map(item=>item.value).join(','));
    url.searchParams.set('host', searchHostText);
    url.searchParams.set('guest', searchGuestText);
    url.searchParams.set('vvip', searchVVIP);
    url.searchParams.set('limit', pageLimit);
    url.searchParams.set('page', 1);
    window.history.pushState({}, '', url);

    setCurrentPage(1);
    getReservations();
  }

  const onOpenItem = (item) => {
    // console.log(reservation_id)
    // dispatch(reservation(reservation_id))
    // .then((res) => {
      setSelectedItem(item);
      setIsReservationView(true);
    // })
  }

  const onCancelItem = (item) => {
    // dispatch(Reservation(Reservation_id))
    // .then((res) => {
    //   setSelectedItem(res);
    //   setIsReservationCancel(true);
    // })
    setSelectedItem(item);
    setIsReservationCancel(true);
  }

  const onCancelItemConfirm = () => {
    dispatch(cancelReservation(selectedItem.id))
    .then(res => {
      const index = _.findIndex(currentItems, {id: selectedItem.id});

      currentItems.splice(index, 1, {...currentItems[index], status: 'Canceled'});

      setIsReservationCancel(false);
    })
  }

  const handlePageClick = (event) => {
    const url = new URL(window.location);
    url.searchParams.set('page', event.selected + 1);
    window.history.pushState({}, '', url);
    
    setCurrentPage(event.selected + 1);
  };


  const onChangeAll = (e) => {
    setCheckList(e.target.checked ? idList : [])
  }

  const onChangePageLimit = (e) => {
    const value = e.target.value || 20;
    setPageLimit(value);
    setCurrentPage(1);
  }

  const onChangeEach = (e, id) => {
    if (e.target.checked) {
      setCheckList([...checkList, id]);
    } else {
      setCheckList(checkList.filter((checkedId) => checkedId !== id));
    }
  }

  const onRefusalItemConfirm = () => {
    setErrorSelectIdList([]);
    dispatch(declineReservation({
      ids: checkList
    }))
    .then((res) => {
      setCheckList([]);
      getReservations();
      setIsReservationRefusalModal(false);
      toast.success('Reservation was refused.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
      });
    })
  }

  const onApprovalItemConfirm = () => {
    const notSelectPersonInCharge = _.filter(currentItems, item => checkList.includes(item.id) && !item.personInCharge);

    if(notSelectPersonInCharge.length > 0){
      setErrorSelectIdList(notSelectPersonInCharge.map(item=>item.id));
      setIsReservationApprovalModal(false);
      setIsNotSetPersionInCharge(true);
      setCurrentItems([...currentItems]);
      // console.log(getRef(`select_${notSelectPersonInCharge[0].id}`)?.current,getRef(`select_${notSelectPersonInCharge[0].id}`)?.current?.offsetTop)
      // window.scrollTo(0, getRef(`select_${notSelectPersonInCharge[0].id}`)?.current?.offsetTop);
      return;
    }
    
    dispatch(approveReservation({
      ids: checkList
    }))
    .then((res) => {
      setCheckList([]);
      getReservations();
      setIsReservationApprovalModal(false);
      toast.success('Reservation was approved.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
      });
    })
  }

  // const onPriorityItemConfirm = () => {
  //   setErrorSelectIdList([]);

  //   dispatch(priorityReservation({
  //     ids: checkList
  //   }))
  //   .then((res) => {
  //     setCheckList([]);
  //     getReservations();
  //     setIsReservationPriorityModal(false);
  //     toast.success('Reservation was priority.', {
  //       position: toast.POSITION.BOTTOM_CENTER,
  //       autoClose: 1000,
  //       hideProgressBar: true,
  //     });
  //   })
  // }

  const onVVIPItemConfirm = () => {
    setErrorSelectIdList([]);

    dispatch(vvipReservation({
      ids: checkList
    }))
    .then((res) => {
      setCheckList([]);
      getReservations();
      toast.success('change completed.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
      });
    })
  }

  const onInProgressItemConfirm = () => {
    setErrorSelectIdList([]);

    dispatch(inProgressReservation({
      ids: checkList
    }))
    .then((res) => {
      setCheckList([]);
      getReservations();
      setIsReservationInProgressModal(false);
      toast.success('Reservation was In Progress.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
      });
    })
  }

  const onSendMailReservationStatusConfirm = () => {
    setErrorSelectIdList([]);

    dispatch(sendMailReservationStatus({
      ids: checkList
    }))
    .then((res) => {
      setCheckList([]);
      setIsSendMailReservationInfoModal(false);
      toast.success('You have successfully sent an email.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
      });
    })
  }

  const onDeleteItemConfirm = () => {
    setErrorSelectIdList([]);
    dispatch(deleteManyReservation({
      ids: checkList
    }))
    .then((res) => {
      setCheckList([]);
      setIsReservationDeleteModal(false);
      getReservations();
      toast.success('Delete completed.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
      });
    })
  }

  const onChangePersonInCharge = (item, personInCharge) => {
    dispatch(updateReservationPersonInCharge({
      id: item.id,
      personInCharge: personInCharge,
      checkDateTime: false,
      sendNotiEmail: false
    }))
    .then((res) => {
      let foundIndex = currentItems.findIndex(x => x.id === item.id);
      item.personInCharge = personInCharge;
      currentItems[foundIndex] = item;
      setErrorSelectIdList(errorSelectIdList.filter(id=>id!==item.id));
      setCurrentItems([...currentItems]);
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  const onChangeLabelBgColor = (item, bgColor) => {
    dispatch(updateReservationLabel({
      id: item.id,
      label: {
        bgColor: bgColor,
        tempText: item?.label?.tempText,
        text: item?.label?.text
      },
      checkDateTime: false,
      sendNotiEmail: false
    }))
    .then((res) => {
      let foundIndex = currentItems.findIndex(x => x.id === item.id);
      item.label = {
        bgColor: bgColor,
        tempText: item?.label?.tempText,
        text: item?.label?.text
      };
      currentItems[foundIndex] = item;
      setCurrentItems([...currentItems]);
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  const onUpdateLabelText = (item, text) => {
    let foundIndex = currentItems.findIndex(x => x.id === item.id);
    item.label = {
      bgColor: item?.label?.bgColor,
      tempText: text,
      text: item?.label?.text,
    };
    currentItems[foundIndex] = item;
    setCurrentItems([...currentItems]);
  }

  const onChangeLabelText = (item, text) => {
    dispatch(updateReservationLabel({
      id: item.id,
      label: {
        bgColor: item?.label?.bgColor,
        text: item?.label?.tempText
      },
      checkDateTime: false,
      sendNotiEmail: false
    }))
    .then((res) => {
      let foundIndex = currentItems.findIndex(x => x.id === item.id);
      item.label = {
        ...item.label,
        text: item?.label?.tempText,
      };
      currentItems[foundIndex] = item;
      setCurrentItems([...currentItems]);
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  const onUpdateMemo = (item, text) => {
    let foundIndex = currentItems.findIndex(x => x.id === item.id);
    item.memo = text;
    currentItems[foundIndex] = item;
    setCurrentItems([...currentItems]);
  }

  const onChangeMemo = (item) => {
    dispatch(updateReservationMemo({
      id: item.id,
      memo: item.memo,
      checkDateTime: false,
      sendNotiEmail: false
    }))
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  // 스크롤하면 하단 버튼 고정
const [addFixedClass, setAddFixedClass] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 200) {
        setAddFixedClass(true);
      } else {
        setAddFixedClass(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const displayEmail = winHtml => {
    const w= 800; 
    const h= 600;

    const winUrl = URL.createObjectURL(
      new Blob([winHtml], { type: "text/html" })
    );

    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    const newWindow = window.open(winUrl, '이메일 발송내용', 
      `
      scrollbars=yes,
      width=${w}, 
      height=${h}, 
      top=${top}, 
      left=${left}
      `
    )
  }

  const generateTimeOptions = (start, end) => {
    const options = [];
    let current = new Date(`2000-01-01T${start}`);
    const endDate = new Date(`2000-01-01T${end}`);

    while (current <= endDate) {
      const timeString = current.toTimeString().slice(0, 5);
      options.push(timeString);
      current.setHours(current.getHours() + 1);
    }

    return options;
  }; 

  return (
    <div className='container_adm'>
      <div className=''>
        <div className='search_wrap mb40'>
          <div className='input_search-wrap'>
            <input type='text' className='input_search' placeholder='ID' defaultValue={searchId} onChange={e=>setSearchId(e.target.value)} value={searchId}/>
            <button type="button" onClick={handleSearch}></button>
          </div>
          {/* <input type='date' placeholder='date' min='2022-09-01' onChange={e=>setSearchDate(e.target.value)}/> */}
          {/* <DatePicker
              minDate={new Date("2022-09-01 00:00:00")}
              selected={searchDate}
              dateFormat="yyyy-MM-dd"
              placeholderText="Date"
              // isClearable
              onChange={date=>setSearchDate(date)}
            /> */}
          <select onChange={e=> {
            setSearchDate(e.target.value) 
            // setSearchTime('')
          }} defaultValue={searchDate} className='select_adm'>
            <option value='' selected={searchDate===''}>Date</option>
            {
              Object.keys(_.groupBy(tourTimeData, row => row.date)).map((time) => (
                <option value={time} selected={searchDate===time}><Moment format='DD.MMM.YYYY'>{time}</Moment></option>
              ))
            }
          </select>
          <select className='select_adm' onChange={e=>setSearchTime(e.target.value)} defaultValue={searchTime}>
            <option value='' selected={searchTime===''}>Time</option>
            {
              _.groupBy(tourTimeData, row => row.date)[searchDate ? searchDate : '2025-01-07'].map((item) => (
                <option value={item.time} selected={searchTime===item.time}>{item.label}</option>
              ))
            }
          </select>
          {/* <select onChange={e=>setSearchStatus(e.target.value)}>
            <option value='' selected={searchStatus===''}>Status</option>
            <option value='In Progress' selected={searchStatus==='In Progress'}>In Progress</option>
            <option value='Approved' selected={searchStatus==='Approved'}>Approved</option>
            <option value='Priority' selected={searchStatus==='Priority'}>Priority</option>
            <option value='Canceled' selected={searchStatus==='Canceled'}>Canceled</option>
            <option value='Declined' selected={searchStatus==='Declined'}>Declined</option>
          </select> */}
          <select onChange={e=>{
            setSearchLanguage(e.target.value)
            // setSearchDate('');
            // setSearchTime('');
          }} className='select_adm' defaultValue={searchLanguage}>
            <option value='' selected={searchLanguage===''}>Language</option>
            <option value="Korean" selected={searchLanguage==='Korean'}>Korean / 한국어</option>
            <option value="English" selected={searchLanguage==='English'}>English / 영어</option>
          </select>
          <MultiSelect
            options={options}
            value={searchStatus}
            valueRenderer={(selected, _options) => {
              return selected.length
                ? selected.map(({ label }, index) => index === 0 ? label : ", " + label)
                : "Status";
            }}
            onChange={setSearchStatus}
            labelledBy="Status"
            disableSearch={true}
            style={{width: '500px'}}
          />
          <div className='input_search-wrap'>
            <input type='text' className='input_search'placeholder='Host Name' onChange={e=>setSearchHostText(e.target.value)}  value={searchHostText}/>
            <button type="button" onClick={handleSearch}></button>
          </div>
          <div className='input_search-wrap'>
            <input type='text' className='input_search'placeholder='Guest Name' onChange={e=>setSearchGuestText(e.target.value)} value={searchGuestText}/>
            <button type="button" onClick={handleSearch}></button>
          </div>
          {/* <div className=''>
            <input type='checkbox' id="vvip_cb"  style={{height: '40px'}}/>
            <label htmlFor='vvip_cb'>
              <img src={require('@assets/images/icon_vvip.svg').default} alt='vip' />
            </label>
          </div> */}
          <div className='' style={{display:'flex'}}>
            <input type='checkbox'  id="cb_priority" onChange={()=>setSearchVVIP(!searchVVIP)} checked={searchVVIP} readOnly/>
            {/* <span className='bg_green' style={{width:'38px', height:'38px', marginRight: '5px', display: 'inline-block'}}></span> */}
            <label for="cb_priority" style={{alignSelf: 'center', marginLeft:'10px'}}><img src={require('@assets/images/icon_vvip.svg').default} alt='vip' style={{height: '30px'}} /></label>
            {/* 라벨 텍스트만 vvip로 바꿨습니다 */}
          </div>
          <button type="button" className='btn-reset' onClick={()=>{
              setSearchId('');
              setSearchDate('');
              setSearchTime('');
              setSearchStatus([]);
              setSearchLanguage('');
              setSearchHostText('');
              setSearchGuestText('');
              setSearchVVIP(false);
              setSearchReset(true);
          }}><img src={resetIcon} alt='reset' /></button>
        </div>
        <div className='adm_title-wrap mb20'>
          <div>
            <h2 style={{display:'inline-block'}}>VIP Tour Reservation List</h2>
            <select onChange={onChangePageLimit} className='select_adm w100x'>
              <option value='20' selected={pageLimit === 20}>20</option>
              <option value='50' selected={pageLimit === 50}>50</option>
              <option value='100' selected={pageLimit === 40}>100</option>
            </select>
          </div>
          <button className='btn-excel' onClick={()=>onDownloadExcel()}>Export to Excel</button>
        </div>
        <table className="table_adm adm_rsv_table mb60">
            <thead>
              <tr>
                <th scope="col" rowSpan="2" style={{width: 45}}>NO</th>
                <th scope='col' rowSpan="2" style={{width: 35}}><input type='checkbox' className='chk_black hk_white' onChange={onChangeAll} checked={checkList.length === idList.length} /></th>
                <th scope="col" rowSpan="2">ID</th>
                <th scope="col" colSpan="2">Tour Request</th>
                <th scope="col" colSpan="2">VIP Tour PIC</th>
                <th scope="col" colSpan="2">Samsung Host</th>
                <th scope='col' colSpan="3">VIP Guests</th>
                <th scope='col' rowSpan='2'>The<br/>person in<br/>charge</th>
                <th scope='col' rowSpan='2'>Detail</th>
                <th scope="col" rowSpan="2">Status</th>
                <th scope="col" rowSpan="2">Cancellation/<br/>Modification</th>
              </tr>
              <tr>
                <th scope='col' className='two'>Date & Time</th>
                <th scope='col' className='two'>Tour Language</th>
                <th scope='col' className='two'>Name</th>
                <th scope='col' className='two'>Mobile</th>
                <th scope='col' className='two'>Executive<br/>Name</th>
                <th scope='col' className='two'>Executive<br/>Position</th>
                {/* <th scope='col' className='two'>Number of Invitees</th>
                <th scope='col' className='two'>Number of Samsung employees</th> */}
                <th scope='col' className='two'>Name</th>
                <th scope='col' className='two'>Company</th>
                <th scope='col' className='two'>Number<br/>(Invitees) &<br/>Number<br/>(Employee)</th>
              </tr>
            </thead>
            <tbody>
              {currentItems && currentItems.map((item, index)=>(
                <>
                <tr key={item.id}>
                  <td rowSpan="2">{totalItemCount - (currentPage - 1) * pageLimit - index}</td>
                  <td rowSpan="2"><input type='checkbox' className='chk_black' onChange={(e) => onChangeEach(e, item.id)} checked={checkList.includes(item.id)} /></td>
                  <td>{item.userId}</td>
                  <td><Moment format='DD.MMM.YYYY'>{item.date}</Moment><br/>{item.time}</td>
                  <td>{item.language}</td>
                  <td>{item.pic.firstName} {item.pic.lastName}</td>
                  <td>{item.pic.countryCode} {item.pic.mobile}</td>
                  <td>{item.host.executiveName}</td>
                  <td>{item.host.executivePosition}</td>
                  {/* <td>{item.inviteNumber}</td>
                  <td>{item.inviteSamsungEmployeesNumber}</td> */}
                  <td style={{whiteSpace:'nowrap'}}>{item?.guests[0]?.name}</td>
                  <td>{item?.guests[0]?.company}</td>
                  <td style={{maxWidth:'80px'}}>{item.inviteNumber} / {item.inviteSamsungEmployeesNumber}</td>
                  <td>
                    <select onChange={e=>onChangePersonInCharge(item, e.target.value)} ref={setRef(`select_${item.id}`)} className={`select_adm select_pcharge ${errorSelectIdList.includes(item.id) ? 'bc_red' : ''}`}>
                      <option value=''></option>
                      {
                        item.language === 'Korean' &&
                        <>
                        <option value='k1' selected={item.personInCharge==='k1'}>Kor1</option>
                        <option value='k2' selected={item.personInCharge==='k2'}>Kor2</option>
                        <option value='k3' selected={item.personInCharge==='k3'}>Kor3</option>
                        </>
                      }
                      { 
                        item.language === 'English' &&
                        <>
                        <option value='e1' selected={item.personInCharge==='e1'}>Eng1</option>
                        <option value='e2' selected={item.personInCharge==='e2'}>Eng2</option>
                        <option value='e3' selected={item.personInCharge==='e3'}>Eng3</option>
                        </>
                      }
                    </select>
                  </td>
                  <td>
                    {/* <button className="btn-view" data-toggle="modal" data-target="#modalInviteesView"><span className="spsans">View</span></button> */}
                    {/* {item.status !== 'Canceled' && (<button className='btn-view' onClick={()=>onOpenItem(item)}><span className='spsans'>View</span></button>)} */}
                    {item.status !== 'Canceled' && (<Link to={basePath ? `/${basePath}/admin/tour/${item.id}${window.location.search}` : `/admin/tour/${item.id}${window.location.search}`}><button className='btn-view'><span className='spsans'>View</span></button></Link>)}
                  </td>
                  <td className={`nowrap`}>
                  {/* <td className={`nowrap ${item.status === 'Priority' ? 'bg_green fc_white' : ''}`}> */}
                    <div className={`status_wrap${item.isVVIP ? ' vvip' : ''}`}>
                      <span className={`${item.status === 'Approved' ? 'warning' : ''}`}>{item.status}</span>
                    </div>
                  </td>
                  <td style={{maxWidth:'130px'}}>
                    {/* {
                      item.status === 'In Progress' ? (<Link to={basePath ? `/${basePath}/admin/tour/${item.id}/modify` : `/admin/tour/${item.id}/modify`}><button className="btn-modify-r" disabled={item.status !== 'In Progress'}><span className="spsans">Modify</span></button></Link>) : (<button className="btn-modify-r" disabled={true}><span className="spsans">Modify</span></button>)
                    } */}
                    {item.status !== 'Canceled' ? <Link to={basePath ? `/${basePath}/admin/tour/${item.id}/modify${window.location.search}` : `/admin/tour/${item.id}/modify${window.location.search}`}><button className="btn-modify-r"><span className="spsans">Modify</span></button></Link> : <button className="btn-modify-r" disabled={true}><span className="spsans">Modify</span></button> }
                  </td>
                </tr>
                <tr>
                  <td style={{padding: 0}}>
                    <label htmlFor='tourMemo' className={`tour_memo ${openLabelBoxId === item.id ? 'open' : ''}`} data-color={item?.label?.bgColor} onClick={()=>setOpenLabelBoxId(item.id)}>
                      {/*  
                      tour_memo_box에서 텍스트값이 입력되면 텍스트 값으로 본문 내용이 변경 (텍스트+img태그 모두)
                      tour_memo에 open클래스가 추가되면 .tour_memo_box가 열리게 했는데, 현재 모든 박스가 같이 열리는 이슈가 있습니다.
                      */}
                      {item?.label?.text ? item?.label?.text : 'Label'}
                      {!item?.label?.text && <img src={editIcon} alt='수정'/>}
                    </label>
                    <div className='tour_memo_box' ref={setRef(`labelBox_${item.id}`)}>
                      <div className='tour_memo_box__colors'>
                        <span>BG Color</span> &nbsp; &nbsp;
                        <input type='radio' name='memoBg' id='BgWhite' value='white' onChange={e=>onChangeLabelBgColor(item, e.target.value)}/>
                        <input type='radio' name='memoBg' id='BgRed' value='red' onChange={e=>onChangeLabelBgColor(item, e.target.value)}/>
                        <input type='radio' name='memoBg' id='BgGreen' value='green' onChange={e=>onChangeLabelBgColor(item, e.target.value)}/>
                        <input type='radio' name='memoBg' id='BgBlue' value='blue' onChange={e=>onChangeLabelBgColor(item, e.target.value)}/>
                        <input type='radio' name='memoBg' id='BgGold' value='gold' onChange={e=>onChangeLabelBgColor(item, e.target.value)}/>
                        <input type='radio' name='memoBg' id='BgBlack' value='black' onChange={e=>onChangeLabelBgColor(item, e.target.value)}/>
                        <input type='radio' name='memoBg' id='BgGrey' value='grey' onChange={e=>onChangeLabelBgColor(item, e.target.value)}/>
                      </div>
                      <input type='text' onChange={e=>onUpdateLabelText(item, e.target.value)} onBlur={()=>onChangeLabelText(item)} placeholder={'Text'} value={item?.label?.tempText} maxLength={6}/>
                    </div>
                  </td>
                  <td colSpan='11'>
                    <input type='text' className='input_none' onChange={e=>onUpdateMemo(item, e.target.value)} onBlur={()=>onChangeMemo(item)} value={item?.memo} placeholder=''/>
                  </td>
                  <td>Email</td>
                  <td>
                    {
                      item?.emailHist?.length > 0 ? <>
                      <ul>
                        <li><a href='#' onClick={()=>displayEmail(item.emailHist[item.emailHist.length-1].html)}><Moment format='DD.MMM.YYYY HH:mm'>{item.emailHist[item.emailHist.length-1].created}</Moment></a></li>
                      </ul>
                      </> : '-'
                    }
                  </td>
                </tr>
                </>
              ))}
              {/* <tr>
                <td>03</td>
                <td>22.08.11<br/>12:00~12:40</td>
                <td>English</td>
                <td>Sydney Kim</td>
                <td>+82 10-1234-5678</td>
                <td>kangnyun kim</td>
                <td>CEO</td>
                <td className='nowrap'>Joe Kim<br/>+82 10-2234-3567</td>
                <td>3</td>
                <td><button className="btn-view" data-toggle="modal" data-target="#modalInviteesView"><span className="spsans">View</span></button></td>
                <td className="nowrap"><strong>In Process</strong></td>
                <td style={{maxWidth:'130px'}}>
                  <button className="btn-cancel-r mb5"><span className="spsans">Cancel</span></button>
                  <button className="btn-modify-r"><span className="spsans">Modify</span></button>
                </td>
              </tr>
              <tr>
                <td>02</td>
                <td>2022.08.22<br/>12:00-12:40</td>
                <td>English</td>
                <td>Seongyeul Park</td>
                <td>Director</td>
                <td>Seongyeul Park</td>
                <td className="nowrap">+61 0402-661-931</td>
                <td className="nowrap">Kangnyun Kim<br/>+82-10-2668-2351</td>
                <td>2</td>
                <td><button className="btn-view"><span className="spsans">View</span></button></td>
                <td className="nowrap"><strong>Canceled</strong></td>
                <td style={{maxWidth:'130px'}}>-</td>
              </tr>
              <tr>
                <td>01</td>
                <td>2022.08.22<br/>12:00-12:40</td>
                <td>English</td>
                <td>Seongyeul Park</td>
                <td>Director</td>
                <td>Seongyeul Park</td>
                <td className="nowrap">+61 0402-661-931</td>
                <td className="nowrap">Kangnyun Kim<br/>+82-10-2668-2351</td>
                <td>3</td>
                <td><button className="btn-view"><span className="spsans">View</span></button></td>
                <td className="nowrap"><strong className="warning">Approved</strong></td>
                <td style={{maxWidth:'130px'}}>-</td>
              </tr> */}
            </tbody>
        </table>
        <div className='paging mb60'>
          <ReactPaginate
            breakLabel='...'
            nextLabel={<span className='paging_end pl30'></span>}
            onPageChange={handlePageClick}
            forcePage={ currentPage - 1 }
            pageRangeDisplayed={3}
            pageCount={totalPageCount}
            previousLabel={<span className='paging_first pl30'></span>}
            renderOnZeroPageCount={null}
            activeLinkClassName='paging_num paging_current'
            pageLinkClassName='paging_num'
            breakLinkClassName='paging_num'
            previousLinkClassName='paging_num'
            nextLinkClassName='paging_num'
          />
        </div>
        <div className={`buttons-center ${addFixedClass ? 'fixed' : ''}`}>
            <button className='btn-cancel mr10 bg_gray' type='button' onClick={()=>setIsReservationDeleteModal(true)} disabled={checkList.length === 0}><span className='spsans'>Delete Selection</span></button>
            <button className='btn-mail mr10' type='button' disabled={checkList.length === 0} onClick={()=>setIsReservationInProgressModal(true)}><span className='spsans'>In Progress</span></button>
            <button className='btn-submit mr10 bg_red' type='button' onClick={()=>setIsReservationRefusalModal(true)} disabled={checkList.length === 0}><span className='spsans'>Refusal</span></button>
            {/* <button className='btn-submit mr10 bg_green' type='button' onClick={()=>setIsReservationPriorityModal(true)} disabled={checkList.length === 0}><span className='spsans'>Priority</span></button> */}
            <button className='btn-submit mr10 bg_blue' type='button' onClick={()=>setIsReservationApprovalModal(true)} disabled={checkList.length === 0}><span className='spsans'>Approval</span></button>
            <Link to={basePath ? `/${basePath}/admin/tour/reservation` : "/admin/tour/reservation"} className='mr10'><button className='btn-submit mr10 bg_black' type='button'><span className='spsans'>Add Reservation</span></button></Link>
            <button className='btn-mail mr10' type='button' disabled={checkList.length === 0} onClick={()=>setIsSendMailReservationInfoModal(true)}><span className='spsans'>Send email</span></button>
            <button className='btn-vvip' type='button' onClick={()=>onVVIPItemConfirm()}><span className='spsans'>VVIP</span></button>
        </div>
        <div className="modal fade in" id="modalCancel" style={{display: isReservationRefusalModal ? 'block' : 'none'}}>
            <div className="modal-dialog modal-dialog_lg modal-dialog-centered">
              <div className="modal-content modal-content-p0">
                <div className="modal-body" style={{padding:'95px 45px 65px'}}>
                  <strong>Refuse this Reservation?</strong>
                  <div className=' mb30'></div>
                  <table className="table_adm adm_rsv_table mb60">
                    <thead>
                      <tr>
                        <th scope="col" rowSpan="2">ID</th>
                        <th scope="col" colSpan="2">Tour Request</th>
                        <th scope="col">VIP Tour PIC</th>
                        <th scope="col" rowSpan="2">Status</th>
                      </tr>
                      <tr>
                        <th scope='col' className='two'>Date & Time</th>
                        <th scope='col' className='two'>Tour Language</th>
                        <th scope='col' className='two'>Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems && _.filter(currentItems, r=>checkList.includes(r.id)).map((item, index)=>( 
                        <>
                        <tr key={item.id}>
                          <td>{item.userId}</td>
                          <td><Moment format='DD.MMM.YYYY'>{item.date}</Moment><br/>{item.time}</td>
                          <td>{item.language}</td>
                          <td>{item.pic.firstName} {item.pic.lastName}</td>
                          <td className={`nowrap`}>
                            <div className={`status_wrap${item.isVVIP ? ' vvip' : ''}`}>
                              <span className={`${item.status === 'Approved' ? 'warning' : ''}`}>{item.status}</span>
                            </div>
                          </td>
                        </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="modal-footer">
                  <button type="button" style={{maxWidth:200}} className="btn-done ssans" onClick={()=>onRefusalItemConfirm()}>Yes</button>
                  &nbsp;
                  <button type="button" className="btn-cancel mr10 ssans" onClick={()=>setIsReservationRefusalModal(false)}>No</button>
                </div>
              </div>
            </div>
        </div>
        <div className="modal fade in" id="modalCancel" style={{display: isReservationApprovalModal ? 'block' : 'none'}}>
            <div className="modal-dialog modal-dialog_lg modal-dialog-centered">
              <div className="modal-content modal-content-p0">
                <div className="modal-body" style={{padding:'95px 45px 65px'}}>
                  <strong>Approve this Reservation?</strong>
                  <div className=' mb30'></div>
                  <table className="table_adm adm_rsv_table mb60">
                    <thead>
                      <tr>
                        <th scope="col" rowSpan="2">ID</th>
                        <th scope="col" colSpan="2">Tour Request</th>
                        <th scope="col">VIP Tour PIC</th>
                        <th scope="col" rowSpan="2">Status</th>
                      </tr>
                      <tr>
                        <th scope='col' className='two'>Date & Time</th>
                        <th scope='col' className='two'>Tour Language</th>
                        <th scope='col' className='two'>Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems && _.filter(currentItems, r=>checkList.includes(r.id)).map((item, index)=>( 
                        <>
                        <tr key={item.id}>
                          <td>{item.userId}</td>
                          <td><Moment format='DD.MMM.YYYY'>{item.date}</Moment><br/>{item.time}</td>
                          <td>{item.language}</td>
                          <td>{item.pic.firstName} {item.pic.lastName}</td>
                          <td className={`nowrap`}>
                            <div className={`status_wrap${item.isVVIP ? ' vvip' : ''}`}>
                              <span className={`${item.status === 'Approved' ? 'warning' : ''}`}>{item.status}</span>
                            </div>
                          </td>
                        </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="modal-footer">
                  <button type="button" style={{maxWidth:200}} className="btn-done ssans" onClick={()=>onApprovalItemConfirm()}>Yes</button>
                  &nbsp;
                  <button type="button" className="btn-cancel mr10 ssans" onClick={()=>setIsReservationApprovalModal(false)}>No</button>
                </div>
              </div>
            </div>
        </div>
        {/* <div className="modal fade in" id="modalCancel" style={{display: isReservationPriorityModal ? 'block' : 'none'}}>
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content modal-content-p0">
                <div className="modal-body" style={{padding:'95px 45px 65px'}}>
                  <strong>Priority this Reservation?</strong>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn-done ssone" onClick={()=>onPriorityItemConfirm()}>Yes</button>
                  &nbsp;
                  <button type="button" className="btn-cancel mr10 ssone" onClick={()=>setIsReservationPriorityModal(false)}>No</button>
                </div>
              </div>
            </div>
        </div> */}
        <div className="modal fade in" id="modalCancel" style={{display: isSendMailReservationInfoModal ? 'block' : 'none'}}>
            <div className="modal-dialog modal-dialog_xlg modal-dialog-centered">
              <div className="modal-content modal-content-p0">
                <div className="modal-body" style={{padding:'95px 45px 65px'}}>
                  <strong>Would you like to send an email?</strong>
                  <div className=' mb30'></div>
                  <table className="table_adm adm_rsv_table mb60">
                    <thead>
                      <tr>
                        <th scope="col" rowSpan="2">ID</th>
                        <th scope="col">Account Email</th>
                        <th scope="col" colSpan="2">Tour Request</th>
                        <th scope="col">VIP Tour PIC</th>
                        <th scope="col" rowSpan="2">Status</th>
                      </tr>
                      <tr>
                        <th scope="col">Notification Email</th>
                        <th scope='col' className='two'>Date & Time</th>
                        <th scope='col' className='two'>Tour Language</th>
                        <th scope='col' className='two'>Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems && _.filter(currentItems, r=>checkList.includes(r.id)).map((item, index)=>( 
                        <>
                        <tr key={item.id}>
                          <td rowSpan={2}>{item.userId}</td>
                          <td>{item.account.email}</td>
                          <td rowSpan={2}><Moment format='DD.MMM.YYYY'>{item.date}</Moment><br/>{item.time}</td>
                          <td rowSpan={2}>{item.language}</td>
                          <td rowSpan={2}>{item.pic.firstName} {item.pic.lastName}</td>
                          <td rowSpan={2} className={`nowrap`}>
                            <div className={`status_wrap${item.isVVIP ? ' vvip' : ''}`}>
                              <span className={`${item.status === 'Approved' ? 'warning' : ''}`}>{item.status}</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>{item.pic.recv_email || item.account.email}</td>
                        </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="modal-footer">
                  <button type="button" style={{maxWidth:200}} className="btn-done ssans" onClick={()=>onSendMailReservationStatusConfirm()}>Yes</button>
                  &nbsp;
                  <button type="button" className="btn-cancel mr10 ssans" onClick={()=>setIsSendMailReservationInfoModal(false)}>No</button>
                </div>
              </div>
            </div>
        </div>
        <div className="modal fade in" id="modalCancel" style={{display: isReservationInProgressModal ? 'block' : 'none'}}>
            <div className="modal-dialog modal-dialog_lg modal-dialog-centered">
              <div className="modal-content modal-content-p0">
                <div className="modal-body" style={{padding:'95px 45px 65px'}}>
                  <strong>In Progress this Reservation?</strong>
                  <div className=' mb30'></div>
                  <table className="table_adm adm_rsv_table mb60">
                    <thead>
                      <tr>
                        <th scope="col" rowSpan="2">ID</th>
                        <th scope="col" colSpan="2">Tour Request</th>
                        <th scope="col">VIP Tour PIC</th>
                        <th scope="col" rowSpan="2">Status</th>
                      </tr>
                      <tr>
                        <th scope='col' className='two'>Date & Time</th>
                        <th scope='col' className='two'>Tour Language</th>
                        <th scope='col' className='two'>Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems && _.filter(currentItems, r=>checkList.includes(r.id)).map((item, index)=>( 
                        <>
                        <tr key={item.id}>
                          <td>{item.userId}</td>
                          <td><Moment format='DD.MMM.YYYY'>{item.date}</Moment><br/>{item.time}</td>
                          <td>{item.language}</td>
                          <td>{item.pic.firstName} {item.pic.lastName}</td>
                          <td className={`nowrap`}>
                            <div className={`status_wrap${item.isVVIP ? ' vvip' : ''}`}>
                              <span className={`${item.status === 'Approved' ? 'warning' : ''}`}>{item.status}</span>
                            </div>
                          </td>
                        </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="modal-footer">
                  <button type="button" style={{maxWidth:200}} className="btn-done ssans" onClick={()=>onInProgressItemConfirm()}>Yes</button>
                  &nbsp;
                  <button type="button" className="btn-cancel mr10 ssans" onClick={()=>setIsReservationInProgressModal(false)}>No</button>
                </div>
              </div>
            </div>
        </div>
        <div className="modal fade in" id="modalCancel" style={{display: isReservationDeleteModal ? 'block' : 'none'}}>
            <div className="modal-dialog modal-dialog_lg modal-dialog-centered">
              <div className="modal-content modal-content-p0">
                <div className="modal-body" style={{padding:'95px 45px 65px'}}>
                  <strong>Are you sure you want to delete?</strong>
                  <div className=' mb30'></div>
                  <table className="table_adm adm_rsv_table mb60">
                    <thead>
                      <tr>
                        <th scope="col" rowSpan="2">ID</th>
                        <th scope="col" colSpan="2">Tour Request</th>
                        <th scope="col">VIP Tour PIC</th>
                        <th scope="col" rowSpan="2">Status</th>
                      </tr>
                      <tr>
                        <th scope='col' className='two'>Date & Time</th>
                        <th scope='col' className='two'>Tour Language</th>
                        <th scope='col' className='two'>Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems && _.filter(currentItems, r=>checkList.includes(r.id)).map((item, index)=>( 
                        <>
                        <tr key={item.id}>
                          <td>{item.userId}</td>
                          <td><Moment format='DD.MMM.YYYY'>{item.date}</Moment><br/>{item.time}</td>
                          <td>{item.language}</td>
                          <td>{item.pic.firstName} {item.pic.lastName}</td>
                          <td className={`nowrap`}>
                            <div className={`status_wrap${item.isVVIP ? ' vvip' : ''}`}>
                              <span className={`${item.status === 'Approved' ? 'warning' : ''}`}>{item.status}</span>
                            </div>
                          </td>
                        </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="modal-footer">
                  <button type="button" style={{maxWidth:200}} className="btn-done ssans" onClick={()=>onDeleteItemConfirm()}>Yes</button>
                  &nbsp;
                  <button type="button" className="btn-cancel mr10 ssans" onClick={()=>setIsReservationDeleteModal(false)}>No</button>
                </div>
              </div>
            </div>
        </div>
        <div className="modal fade in" id="modalInviteesView"  style={{display: isReservationView ? 'block' : 'none' }}>
          <div className="modal-dialog modal-dialog_lg modal-dialog-centered">
            <div className="modal-content">
            <ReactToPrint
              trigger={() => <button className='btn-print'></button>}
              content={() => componentRef.current}
            />
            <button className='btn-modalclose' onClick={()=>setIsReservationView(false)}></button>
            <div ref={componentRef}>
              <div className="modal-header">
                  <h3 className="modal-title">Number of invited VIP Guests</h3>
              </div>
              <div className="modal-body">
              {
                  selectedItem &&
                  (
                <ul className="modal_view clear">
                  {
                    selectedItem.guests.map(guest=>(
                      <>
                        <li className="view-third">
                          <small>Name</small>
                          <span className="modal_value">{guest.name}</span>
                        </li>
                        <li className="view-third">
                          <small>Company</small>
                          <span className="modal_value">{guest.company}</span>
                        </li>
                        <li className="view-third">
                          <small>Position</small>
                          <span className="modal_value">{guest.position}</span>
                        </li>
                      </>
                    ))
                  }
                </ul>
                  )
                }
              </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={()=>setIsReservationView(false)}><span className="spsans">OK</span></button>
              </div>
            </div>
          </div>
      </div>
      <div className='modal fade in' id='modalEmailDbchk' style={{display: isNotSetPersionInCharge ? 'block' : 'none'}}>
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content modal-content-p0'>
              <div className='modal-body'>
                <strong>Please select person in charge.</strong>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn-send sssb' onClick={()=>setIsNotSetPersionInCharge(false)}>Check</button>
              </div>
            </div>
          </div>
      </div>
    </div>
    </div>
  );
}
