import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { isEmail } from 'validator';
import regionData from '@/data/regionData';
import mobileData from '@/data/mobileData';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import _ from 'lodash';
import history from '@/helpers/history';
import moment from 'moment';
import DatePicker from "react-datepicker";
import arrowUpIcon from '@/assets/icons/arrow_up_thin_black.svg';
import "react-datepicker/dist/react-datepicker.css";

import { updateReservation, reservation, availableDateTime } from '@/actions/tour';
import { setIsLoading } from '@/actions/common';
import { me } from '@/actions/auth';

export default function TourRerservationEdit() {
  const dispatch = useDispatch();
  const params = useParams();

  const validationSchema = Yup.object().shape({
    date: Yup.string().required('Required field'),
    time: Yup.string().required('Required field'),
    language: Yup.string().required('Required field'),
    currentBusinessIssues: Yup.string().required('Required field'),
    inviteNumber: Yup.number().min(1).typeError('Amount must be a number').required('Required field'),
    inviteSamsungEmployeesNumber: Yup.number().min(0).typeError('Amount must be a number').required('Required field'),
    host: Yup.object().shape({
      department: Yup.string().required('Required field'),
      executiveName: Yup.string().required('Required field'),
      executivePosition: Yup.string().required('Required field'),
    }),
    pic: Yup.object().shape({
      firstName: Yup.string().max(40, 'must be at most 40 characters').required('Required field'),
      lastName: Yup.string().max(40, 'must be at most 40 characters').required('Required field'),
      region: Yup.string().required('Required field'),
      organization: Yup.string().required('Required field'),
      gbm: Yup.string().required('Required field'),
      department: Yup.string().required('Required field'),
      email: Yup.string().email('Email is Invalid').required('Required field'),
      countryCode: Yup.string().required('Required field'),
      mobile: Yup.string().max(15, 'must be at most 15 characters').required('Required field')
    }),
    site: Yup.object().shape({
      firstName: Yup.string().max(40, 'must be at most 40 characters').required('Required field'),
      lastName: Yup.string().max(40, 'must be at most 40 characters').required('Required field'),
      countryCode: Yup.string().required('Required field'),
      mobile: Yup.string().max(15, 'must be at most 15 characters').required('Required field')
    }),
    guests: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Required field'),
        company: Yup.string().required('Required field'),
        position: Yup.string().required('Required field')
      })
    ),
    isRequireMobilityAssistance: Yup.boolean(),
    mobilityAssistances: Yup.array().of(
      Yup.object().shape({
        information: Yup.string().required('Required field'),
        expectedService: Yup.array().min(1, 'Required field'),
        note: Yup.string()
      })
    ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema)
  };

  const timeDropDownRef = useRef(null);
  const { register, control, handleSubmit, setValue, setError, reset, formState, watch } = useForm(formOptions);
  const { errors } = formState;
  const { fields: guests, append: guestAppend, update: guestUpdate, remove: guestRemove } = useFieldArray({ name: 'guests', control });
  const { fields: mobilityAssistances, append: mobilityAssistanceAppend, update: mobilityAssistanceUpdate, remove: mobilityAssistanceRemove, replace: mobilityAssistanceReplace } = useFieldArray({ name: 'mobilityAssistances', control });

  const [tourTimes, setTourTimes] = useState(null);
  const [isSameProfile, setIsSameProfile] = useState(false);
  const [isSameProfileSite, setIsSameProfileSite] = useState(false);
  const [showTimeDropdown, setShowTimeDropdown] = useState(false);

  // const [isRequireMobilityAssistance, setIsRequireMobilityAssistance] = useState(false);
  // const [mobilityAssistanceFormData, setMobilityAssistanceFormData] = useState({
  //   information: '',
  //   expectedService: '',
  //   note: ''
  // })
  // const [mobilityAssistanceFormDataRequireError, setMobilityAssistanceFormDataRequireError] = useState({
  //   information: false,
  //   expectedService: false,
  //   note: false
  // })

  const formData = watch();

  useEffect(() => {
    if(isSameProfile){
      dispatch(me())
      .then((res) => {
        // tour.pic = {
        //   firstName: res.firstName,
        //   lastName: res.lastName,
        //   email: res.email,
        //   region: res.region,
        //   organization: res.organization,
        //   countryCode: res.countryCode,
        //   mobile: res.mobile
        // }
        // setTour({...tour});
        setValue('pic', {
          firstName: res.firstName,
          lastName: res.lastName,
          region: res.region,
          organization: res.organization_other || res.organization,
          gbm: '',
          department: '',
          email: res.email,
          countryCode: res.countryCode,
          mobile: res.mobile
        })
      })
    } else {
      setValue('pic', {
        firstName: '',
        lastName: '',
        region: '',
        organization: '',
        gbm: '',
        department: '',
        email: '',
        countryCode: '',
        mobile: ''
      })
    }
  }, [isSameProfile])

  useEffect(() => {
    if(isSameProfileSite){
      dispatch(me())
      .then((res) => {
        setValue('site', {
          firstName: res.firstName,
          lastName: res.lastName,
          countryCode: res.countryCode,
          mobile: res.mobile
        })
      })
    } else {
      setValue('site', {
        firstName: '',
        lastName: '',
        countryCode: '',
        mobile: ''
      })
    }
  }, [isSameProfileSite])

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(availableDateTime({include_id: params.reservation_id}))
    .then(res => {
      setTourTimes(res)
      console.log(res)
      dispatch(reservation(params.reservation_id))
      .then((res) => {
        reset(res);
        if(res.mobilityAssistances.length > 0){
          setValue('isRequireMobilityAssistance', true)
        }
        dispatch(setIsLoading(true));
      })
    })
  }, []);

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleTimeInputClick = () => {
    if(formData.language && formData.date)
      setShowTimeDropdown(!showTimeDropdown);
  };

  const handleTimeSelect = (time) => {
    setValue('time', time)
    setShowTimeDropdown(false);
  };

  const handleClickOutside = (event) => {
    if ( timeDropDownRef.current && !timeDropDownRef.current.contains(event.target) ) {
      setShowTimeDropdown(false);
    }
  };

  // useEffect(() => {
  //   setValue('inviteNumber', fields.length)
  // }, [fields.length])

  const addGuest = () => {
    guestAppend({
      name: '',
      company: '',
      position: ''
    })
  }

  const removeGuest = (index) => {
    guestRemove(index)
  }

  const addMobilityAssistance = () => {
    mobilityAssistanceAppend({
      information: '',
      expectedService: '',
      note: '',
    });
  }

  const removeMobilityAssistance = (index) => {
    mobilityAssistanceRemove(index)
  }

  const onSubmit = (data) => {
    dispatch(updateReservation({
      id: params.reservation_id,
      ...data
    }))
    .then((res) => {
      history.navigate('/viptour/reservation-list', {replace: true})
    })
    .catch(message => {
      dispatch(availableDateTime({include_id: params.reservation_id}))
      .then(res => {
        setTourTimes(res)
        setValue('date', '')
        setValue('time', '')
        setError('date', { message: 'Required field' });
        setError('time', { message: 'Required field' });
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      })

      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });

  }

  const onChangeCheckbox = (e) => {
    if(e.target.checked){
      setIsSameProfile(true);
    }else{
      setIsSameProfile(false);
    }
  }

  const onChangeCheckboxSite = (e) => {
    if(e.target.checked){
      setIsSameProfileSite(true);
    }else{
      setIsSameProfileSite(false);
    }
  }
const [focusedInput, setFocusedInput] = useState(null);

  const handleFocus = (inputName) => {
    setFocusedInput(inputName);
  };

  const handleBlur = () => {
    setFocusedInput(null);
  };
  return (
    <>
    <div className="container_top ticket_top">
      <div className="top-info top-info_cn">
        <h2 className="top-title">VIP Tour Reservation</h2>
      </div>
    </div>
    <div className="container_body-tit">
      <h3></h3>
      <span className="tit-desc-box">* All fields are required </span>
    </div>
    <section className="vip-reservation rsv-edit">
      <form className='form' onSubmit={handleSubmit(onSubmit)}>
        <div className="vip_reservation_item">
          <div className="vip-reservation-tit" style={{marginBottom:'49px'}}>
            <h4>Tour Request</h4>
            <span className="tit-desc"></span>
          </div>
          <div className='form_inner mb100'>
            <div className='rsvtour_request'>
              <div className="form_item">
                <label htmlFor="language" className={`list_label ${focusedInput === 'language' ? 'focused' : ''}`}><span class="tit-desc-p">*</span>Tour Language<span></span>투어 언어</label>
                <div className={`mr20 ${errors.language?.message ? 'is-invalid' : ''}`}>
                  <select 
                  id={`language`} 
                  name={`language`}
                  onFocus={() => handleFocus('language')}
                  onBlur={handleBlur}
                   {...register(`language`,{
                      onChange: (e)=>{
                        setValue('date', '')
                        setValue('time', '')
                      }})}>
                    <option value="">Please select</option>
                    <option value="Korean">Korean / 한국어</option>
                    <option value="English">English / 영어</option>
                  </select>
                  <div className='invalid-feedback'>{errors.language?.message}</div>
                </div>
              </div>
              <div className="form_item">
                <label htmlFor="vip_date" className={`list_label ${focusedInput === 'date' ? 'focused' : ''}`}><span class="tit-desc-p">*</span>Date<span></span>날짜</label>
                <div className={`mr20 ${errors.date?.message ? 'is-invalid' : ''}`}>
                  <DatePicker
                      onFocus={() => handleFocus('date')}
                      minDate={new Date("2025-01-07 10:00:00")}
                      maxDate={new Date("2025-01-10 16:00:00")}
                      selected={formData.date ? new Date(formData.date) : ''}
                      dateFormat="yyyy-MM-dd"
                      placeholderText="Please select"
                      // excludeDates={formData.language && tourTimes[formData.language] ? tourDate.filter(item => !Object.keys(tourTimes[formData.language]).includes(item)).map(date => new Date(`${date} 10:00:00`)) : []}
                      // isClearable
                      excludeDates={tourTimes && tourTimes[formData.language] ? ['2025-01-07','2025-01-08','2025-01-09','2025-01-10'].filter(date=> !Object.keys(tourTimes[formData.language]).includes(date)).map(date=>new Date(date)) : []}
                      disabled={!formData.language}
                      onChange={date=>{
                        setValue('date', moment(date).format('YYYY-MM-DD'))
                        setValue('time', '')
                        // setValue('date', date)
                      }}
                      className='select icon_cal'
                    />
                  <div className='invalid-feedback'>{errors.date?.message}</div>
                </div>
              </div>
              <div className="form_item">
                <label htmlFor="vip_date" className={`list_label ${focusedInput === 'time' ? 'focused' : ''}`}><span class="tit-desc-p">*</span>Time<span></span>시간</label>
                <div className={`${errors.time?.message ? 'is-invalid' : ''}`}>
                  <input
                        type='text'
                        id={`time`}
                        name={`time`}
                        onFocus={() => handleFocus('time')}
                        placeholder='Please select'
                        onBlur={handleBlur}
                        {...register(`time`)}
                        onClick={handleTimeInputClick}
                        readOnly
                    />
                    {showTimeDropdown && (
                    <div ref={timeDropDownRef} className="time-dropdown-container">
                      <div className="time-dropdown-header">
                        <div>Time | 시간</div>
                        <img className='close-btn' src={arrowUpIcon} onClick={()=>setShowTimeDropdown(false)}/>
                      </div>
                      <div className="time-dropdown-content">

                          {formData.language && formData.date && tourTimes[formData.language] && tourTimes[formData.language][formData.date]?.map((value, mindex) => <button type='button' className={`time-button ${formData.time === value.time && 'selected'}`} onClick={() => handleTimeSelect(value.time)}>
                            <span className="time-text">{value.time.split(' ~')[0]}</span>
                            {/* <span className="tilde"> ~</span> */}
                          </button>)}
                      </div>
                    </div>
                    )}
                  <div className='invalid-feedback'>{errors.time?.message}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

          <div className="vip-reservation-tit">
            <h4>VIP Tour PIC</h4>
            <div className="checkbox">
              <input type="checkbox" id="same" className="mr10" onChange={onChangeCheckbox} checked={isSameProfile} />
              <label htmlFor="same">Same as my profile</label>
            </div>
          </div>
          <div className='form_inner mb100'>
            <div className='room-reservation-desc'>
            - 예약 일정 조정, 상세 내역 확인 등 투어 관련 업무 협의가 가능한 신청 담당자 정보 기입<br/>
            - Information of PIC who can communicate with IFA VIP Tour team about VIP tour details including schedule adjustments.
            </div>

            <div className='rsvtour_items'>
              <div className='form_item mb40'>
                <label htmlFor={`pic.firstName`} className={`list_label ${focusedInput === 'pic.firstName' ? 'focused' : ''}`}><span class="tit-desc-p">*</span>Name<span></span>이름</label>
                  <div className='register_name'>
                    <div className={`${errors.pic?.firstName?.message ? 'is-invalid' : ''}`}>
                      <input
                        type='text'
                        id={`pic.firstName`}
                        name={`pic.firstName`}
                        placeholder='First name'
                        onFocus={() => handleFocus('pic.firstName')}
                        onBlur={handleBlur}
                        {...register(`pic.firstName`,{
                          onChange: (e)=>{
                            e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                          }})}
                                              />
                      <div className='invalid-feedback'>{errors.pic?.firstName?.message}</div>
                    </div>
                    <div className={`${errors.pic?.lastName?.message ? 'is-invalid' : ''}`}>
                      <input
                        type='text'
                        id={`pic.lastName`}
                        name={`pic.lastName`}
                        placeholder='Last name'
                        onFocus={() => handleFocus('pic.firstName')}
                        onBlur={handleBlur}
                        {...register(`pic.lastName`,{
                          onChange: (e)=>{
                            e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                          }})}
                                              />
                      <div className='invalid-feedback'>{errors.pic?.lastName?.message}</div>
                    </div>
                  </div>
              </div>

              <div className='form_item mb40'>
                <label htmlFor={`pic.region`} className={`list_label ${focusedInput === 'pic.region' ? 'focused' : ''}`}><span class="tit-desc-p">*</span>Region<span></span>지역</label>
                <div className={`${errors.pic?.region?.message ? 'is-invalid' : ''}`}>
                  <select
                    id={`pic.region`}
                    name={`pic.region`}
                    onFocus={() => handleFocus('pic.region')}
                    onBlur={handleBlur}
                    {...register(`pic.region`)}>
                    <option value=''>Please select</option>
                    {
                      Object.keys(regionData).map((value, mindex) => (
                        <option value={value} key={mindex}>{value}</option>
                      ))
                    }
                  </select>
                  <div className='invalid-feedback'>{errors.pic?.region?.message}</div>
                </div>
              </div>
              {/* <div className={`${errors.pic?.organization?.message ? 'is-invalid' : ''}`}>
                <input
                  type='text'
                  id={`pic.organization`}
                  name={`pic.organization`}
                  placeholder='Organization'
                  onFocus={() => handleFocus('pic.organization')}
                  onBlur={handleBlur}
                  {...register(`pic.organization`)}
                />
                <div className='invalid-feedback'>{errors.pic?.organization?.message}</div>
              </div> */}
              <div className="form_item mb40">
                <label htmlFor={`pic.organization`} className={`list_label ${focusedInput === 'pic.organization' ? 'focused' : ''}`}><span class="tit-desc-p">*</span>Organization<span></span>법인</label>
                <div className={`${errors.pic?.organization?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='text'
                    id={`pic.organization`}
                    placeholder='Enter your organization'
                    name={`pic.organization`}
                    {...register(`pic.organization`)}
                                        onFocus={() => handleFocus('pic.organization')}
                  onBlur={handleBlur}
                  />
                  <div className='invalid-feedback'>{errors.pic?.organization?.message}</div>
                  <div class="invalid-feedback-ex"><span>Ex)</span> NA Office, SEA, SEG, SEI, SEIL</div>
                </div>
              </div>
              {/* <div className='form_item mb40'>
                <label htmlFor={`pic.organization`} className={`list_label ${focusedInput === 'pic.organization' ? 'focused' : ''}`}>Organization<span></span>법인</label>
                <div className={`${errors.pic?.organization?.message ? 'is-invalid' : ''}`}>
                  <select
                    id={`pic.organization`}
                    onFocus={() => handleFocus('pic.organization')}
                    onBlur={handleBlur}
                    name={`pic.organization`}
                    
                    {...register(`pic.organization`, {
                      onChange: (e) => {setValue(`pic.countryCode`, formData?.pic?.region ? regionData[formData.pic.region][formData.pic.organization] : '')},
                    })}>
                    <option value=''>Please select</option>
                    {
                      formData?.pic?.region && Object.keys(regionData[formData.pic.region]).map((value, mindex) => (
                        <option value={value} key={mindex}>{value}</option>
                      ))
                    }
                  </select>
                  <div className='invalid-feedback'>{errors.pic?.organization?.message}</div>
                </div>
              </div> */}
              <div className="form_item mb40">
                <label htmlFor={`pic.gbm`} className={`list_label ${focusedInput === 'pic.gbm' ? 'focused' : ''}`}><span class="tit-desc-p">*</span>Division<span></span>사업부</label>
                <div className={`${errors.pic?.gbm?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='text'
                    id={`pic.gbm`}
                    name={`pic.gbm`}
                    placeholder='Enter your division'

                    {...register(`pic.gbm`)}
                                        onFocus={() => handleFocus('pic.gbm')}
                  onBlur={handleBlur}
                  />
                  <div className='invalid-feedback'>{errors.pic?.gbm?.message}</div>
                </div>
              </div>
              <div className="form_item mb40">
                <label htmlFor={`pic.department`} className={`list_label ${focusedInput === 'pic.department' ? 'focused' : ''}`}><span class="tit-desc-p">*</span>Department<span></span>부서</label>
                <div className={`${errors.pic?.department?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='text'
                    id={`pic.department`}
                    name={`pic.department`}
                    placeholder='Enter your department'

                    {...register(`pic.department`)}
                                        onFocus={() => handleFocus('pic.department')}
                  onBlur={handleBlur}
                  />
                  <div className='invalid-feedback'>{errors.pic?.department?.message}</div>
                </div>
              </div>
              <div className="form_item mb40">
                <label htmlFor={`pic.email`} className={`list_label ${focusedInput === 'pic.email' ? 'focused' : ''}`}><span class="tit-desc-p">*</span>Email<span></span>이메일</label>
                <div className={`${errors.pic?.email?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='email'
                    id={`pic.email`}
                    name={`pic.email`}
                    placeholder='Enter your email'

                    {...register(`pic.email`,{
                      onChange: (e)=>{
                        e.target.value = e.target.value.replace(/[^0-9a-zA-Z-_.@]/g,'').toLowerCase();
                      }})}
                    onFocus={() => handleFocus('pic.email')}
                    onBlur={handleBlur}
                  />
                  <div className='invalid-feedback'>{errors.pic?.email?.message}</div>
                </div>
              </div>
              <div className="form_item form_item-ph">
                <label htmlFor={`countryCode`} className={`list_label ${focusedInput === 'pic.mobile' ? 'focused' : ''}`}><span class="tit-desc-p">*</span>Mobile Phone<span></span>연락처</label>
                  <div className='form_item__wrap'>
                    <div className={`${errors.pic?.countryCode?.message ? 'is-invalid mr20' : 'mr20'}`}>
                      <select
                        id={`pic.countryCode`}
                        name={`pic.countryCode`}
                        onFocus={() => handleFocus('pic.mobile')}
                        onBlur={handleBlur}
                        {...register(`pic.countryCode`)}>
                        <option value=''>Please select</option>
                        {
                          mobileData.map((value, mindex) => (
                            <option value={value} key={mindex}>{value}</option>
                          ))
                        }
                      </select>
                      <div className='invalid-feedback'>{errors.pic?.countryCode?.message}</div>
                    </div>
                    <div className={`${errors.pic?.mobile?.message ? 'is-invalid' : ''}`}>
                      <input
                        type='text'
                        id={`pic.mobile`}
                    placeholder='Enter your mobile number'
                        name={`pic.mobile`}
                        
                        {...register(`pic.mobile`,{
                          onChange: (e)=>{
                          e.target.value = e.target.value.replace(/[^0-9]/g,'');
                          // if(e.target.value.length > 0 && e.target.value.substring(0,1) === '0') {
                          //   e.target.value = e.target.value.substring(1,e.target.value.length)
                          // }
                        }})}
                        onFocus={() => handleFocus('pic.mobile')}
                        onBlur={handleBlur}
                      />
                      <div className='invalid-feedback'>{errors.pic?.mobile?.message}</div>
                    </div>
                </div>
              </div>
            </div>
          </div>

          <div className="vip-reservation-tit">
            <h4>On-site Contact</h4>
            <div className="checkbox">
              <input type="checkbox" id="same2" className="mr10" onChange={onChangeCheckboxSite} checked={isSameProfileSite} />
              <label htmlFor="same2">Same as my profile</label>
            </div>
          </div>
          <div className='form_inner mb100'>
            <div className='room-reservation-desc' style={{width: '100%'}}>
            - 신청자와 베를린 IFA 현장 출장자가 다른 경우, 현장에서 컨택 가능한 출장자 정보 기입<br/>
            - Please enter the contact information of the PIC at the Berlin IFA venue.
            </div>
            <div className='form_item'>
              <label htmlFor={`site.firstName`} className={`list_label ${focusedInput === 'site.firstName' ? 'focused' : ''}`}><span class="tit-desc-p">*</span>Name<span></span>이름</label>
              <div className='register_name'>
                <div className={`${errors.site?.firstName?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='text'
                    id={`site.firstName`}
                    name={`site.firstName`}
                    placeholder='First name'
                    {...register(`site.firstName`,{
                      onChange: (e)=>{
                        e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                      }})}
                      onFocus={() => handleFocus('site.firstName')}
                      onBlur={handleBlur}
                                      />
                  <div className='invalid-feedback'>{errors.site?.firstName?.message}</div>
                </div>
                <div className={`${errors.site?.lastName?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='text'
                    id={`site.lastName`}
                    name={`site.lastName`}
                    placeholder='Last name'
                    {...register(`site.lastName`,{
                      onChange: (e)=>{
                        e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                      }})}
                      onFocus={() => handleFocus('site.firstName')}
                      onBlur={handleBlur}
                                      />
                  <div className='invalid-feedback'>{errors.site?.lastName?.message}</div>
                </div>
              </div>
            </div>
            <div className="form_item form_item-ph">
              <label htmlFor={`countryCode`} className={`list_label ${focusedInput === 'site.mobile' ? 'focused' : ''}`}><span class="tit-desc-p">*</span>Mobile Phone<span></span>연락처</label>
              <div className='form_item__wrap'>
                <div className={`${errors.site?.countryCode?.message ? 'is-invalid mr20' : 'mr20'}`}>
                  <select
                    id={`site.countryCode`}
                    name={`site.countryCode`}
                    onFocus={() => handleFocus('site.mobile')}
                    onBlur={handleBlur}
                    {...register(`site.countryCode`)}>
                    <option value=''>Please select</option>
                    {
                      mobileData.map((value, mindex) => (
                        <option value={value} key={mindex}>{value}</option>
                      ))
                    }
                  </select>
                  <div className='invalid-feedback'>{errors.site?.countryCode?.message}</div>
                </div>
                <div className={`${errors.site?.mobile?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='text'
                    id={`site.mobile`}
                    placeholder='Enter your mobile number'
                    name={`site.mobile`}
                    onFocus={() => handleFocus('site.mobile')}
                  onBlur={handleBlur}
                    {...register(`site.mobile`,{
                      onChange: (e)=>{
                      e.target.value = e.target.value.replace(/[^0-9]/g,'');
                      // if(e.target.value.length > 0 && e.target.value.substring(0,1) === '0') {
                      //   e.target.value = e.target.value.substring(1,e.target.value.length)
                      // }
                      
                    }})}
                                      />
                  <div className='invalid-feedback'>{errors.site?.mobile?.message}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="vip-reservation-tit">
            <h4>Samsung Host</h4>
            <span className="tit-desc"></span>
          </div>
          <div className='form_inner mb100'>
            <div className='room-reservation-desc'>
            - 직접 호스팅하시는 부사장급/지역총괄급 이상 삼성 임원 정보 기입<br />
            - Please enter detail information of Samsung executive who will host the invitees, higher than EVP or Head of Regional Office position.
            </div>
            <div className="form_item mb40">
              <label htmlFor="host_dept" className={`list_label ${focusedInput === 'host.department' ? 'focused' : ''}`}><span class="tit-desc-p">*</span>Department<span></span>소속</label>
              <div className={`${errors.host?.department?.message ? 'is-invalid' : ''}`}>
                <input
                  type='text'
                  id={`host.department`}
                    placeholder='Department'
                  name={`host.department`}
                  onFocus={() => handleFocus('host.department')}
                  onBlur={handleBlur}
                  {...register(`host.department`,{
                    onChange: (e)=>{
                      e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                    }})}
                                  />
                <div className='invalid-feedback'>{errors.host?.department?.message}</div>
              </div>
            </div>
            <div className="form_item">
              <label htmlFor="host.executiveName" className={`list_label ${focusedInput === 'host.executiveName' ? 'focused' : ''}`}><span class="tit-desc-p">*</span>Executive Name<span></span>성함</label>
              <div className={`${errors.host?.executiveName?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='text'
                    id={`host.executiveName`}
                    name={`host.executiveName`}
                    placeholder='Executive name'

                    onFocus={() => handleFocus('host.executiveName')}
                  onBlur={handleBlur}
                    {...register(`host.executiveName`,{
                      onChange: (e)=>{
                        e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                      }})}
                                      />
                <div className='invalid-feedback'>{errors.host?.executiveName?.message}</div>
              </div>
            </div>
            <div className="form_item">
              <label htmlFor="host.executivePosition" className={`list_label ${focusedInput === 'host.executivePosition' ? 'focused' : ''}`}><span class="tit-desc-p">*</span>Executive Position<span></span>직위</label>
              <div className={`${errors.host?.executivePosition ? 'is-invalid' : ''}`}>
                <input
                  type='text'
                  id={`host.executivePosition`}
                    placeholder='Executive position'
                  name={`host.executivePosition`}
                  onFocus={() => handleFocus('host.executivePosition')}
                  onBlur={handleBlur}
                  {...register(`host.executivePosition`,{
                    onChange: (e)=>{
                      e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                    }})}
                                  />
                <div className='invalid-feedback'>{errors.host?.executivePosition?.message}</div>
              </div>
            </div>
          </div>

          <div className="vip-reservation-tit">
            <h4>VIP Guests</h4>
          </div>
          <div className='form_inner bb mb30'>
            <div className='room-reservation-desc'>
            - 입력 주신 인원 수를 바탕으로 투어 리시버/헤드셋이 준비되므로 정확한 방문객 숫자 기입 필요<br/>
            - 쾌적한 관람을 위해 투어 인원 15인 제한<br/>
            - Tour receivers and headsets will be prepared based on the number of people entered below. Please enter exact number of visitors.<br/>
            - Tours are limited to 15 people to maintain a pleasant tour experience for your guests.
            </div>
            <div className='rsvtour_items'>
              <div className="form_item mb30">
                <label htmlFor="num_invitees" className={`num_invite list_label ${focusedInput === 'inviteNumber' ? 'focused' : ''}`}>
                <span class="tit-desc-p">*</span>Number of Invitees<span></span>초대받은 인원 수
                </label>
                <div className={`${errors.inviteNumber?.message ? 'is-invalid' : ''}`}>
                  {/* <input
                    type='text'
                    id={`inviteNumber`}
                    name={`inviteNumber`}
                    {...register(`inviteNumber`,{
                      onChange: (e)=>{
                        e.target.value = e.target.value.replace(/[^0-9]/g,'');
                      }})}
                                    /> */}
                  {/* <select
                    id={`inviteNumber`}
                    name={`inviteNumber`}
                    {...register(`inviteNumber`,{
                      onChange: (e)=>{
                        // if(e.target.value > fields.length){
                        //   Array.from(Array(e.target.value - fields.length).keys()).forEach(()=>{
                        //     addGuest();
                        //   })
                        // } else {
                        //   for (let index = formData.guests.length-1; index >= e.target.value; index--) {
                        //     console.log(index)
                        //     removeGuest(index);
                        //   }
                        // }
                      }})}>
                    {
                      Array.from(Array(10).keys()).map(i=>(
                        <option value={i+1}>{i+1}</option>
                      ))
                    }
                  </select> */}
                  <input
                    type='number'
                    id={`inviteNumber`}
                    name={`inviteNumber`}
                    onFocus={() => handleFocus('inviteNumber')}
                    onBlur={handleBlur}
                    {...register(`inviteNumber`,{
                      onChange: (e)=>{
                        e.target.value = e.target.value.replace(/[^0-9]/g,'');
                      }})}
                                      min={1}
                    max={99}
                  />
                  <div className='invalid-feedback'>{errors.inviteNumber?.message}</div>
                </div>
                {/*<button className="btn-add" type="button"><span className="spsans">Add</span></button>*/}
              </div>
              <div className="form_item mb30">
                <label htmlFor="num_invitees" className={`num_invite list_label ${focusedInput === 'inviteSamsungEmployeesNumber' ? 'focused' : ''}`}>
                <span class="tit-desc-p">*</span>Number of Samsung employees<span></span>동행 삼성 임직원 수
                </label>

                <div className={`${errors.inviteSamsungEmployeesNumber?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='number'
                    id={`inviteSamsungEmployeesNumber`}
                    name={`inviteSamsungEmployeesNumber`}
                    onFocus={() => handleFocus('inviteSamsungEmployeesNumber')}
                    onBlur={handleBlur}
                    {...register(`inviteSamsungEmployeesNumber`,{
                      onChange: (e)=>{
                        e.target.value = e.target.value.replace(/[^0-9]/g,'');
                      }})}
                                      min={0}
                    max={99}
                  />
                  <div className='invalid-feedback'>{errors.inviteSamsungEmployeesNumber?.message}</div>
                </div>
                {/*<button className="btn-add" type="button"><span className="spsans">Add</span></button>*/}
              </div>
            </div>
            <div className='room-reservation-desc mb40'>
              - 최소 한 분의 대표 VIP 게스트 정보 입력 필요<br />
              - Required at least one, representative VIP guest information.
            </div>
            <div className="vip_invitees mb40">
              {
                guests.map((item, i)=>(
                  <div className="vip_invitee mb30" key={i}>
                    <p><span class="tit-desc-p">*</span>No{i+1}.</p>
                    <div className='form_item__wrap'>
                      <div className={`mr20 ${errors.guests?.[i]?.name?.message ? 'is-invalid' : ''}`}>
                        <input
                          type='text'
                          id={`guests[${i}]name`}
                          name={`guests[${i}]name`}
                          placeholder='Name of the guest'
                          {...register(`guests.${i}.name`,{
                            onChange: (e)=>{
                              e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                            }})}
                                                  />
                        <div className='invalid-feedback'>{errors.guests?.[i]?.name?.message}</div>
                      </div>
                      <div className={`mr20 ${errors.guests?.[i]?.company?.message ? 'is-invalid' : ''}`}>
                        <input
                          type='text'
                          id={`guests[${i}]company`}
                          name={`guests[${i}]company`}
                          placeholder='Company of the guest'
                          {...register(`guests.${i}.company`,{
                            onChange: (e)=>{
                              e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                            }})}
                                                  />
                        <div className='invalid-feedback'>{errors.guests?.[i]?.company?.message}</div>
                      </div>
                      <div className={`mr20 ${errors.guests?.[i]?.position?.message ? 'is-invalid' : ''}`}>
                        <input
                          type='text'
                          id={`guests[${i}]position`}
                          name={`guests[${i}]position`}
                          placeholder='Position of the guest'
                          {...register(`guests.${i}.position`,{
                            onChange: (e)=>{
                              e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                            }})}
                                                  />
                        <div className='invalid-feedback'>{errors.guests?.[i]?.position?.message}</div>
                      </div>
                      <button className="btn-remove" type="button" style={{visibility: i > 0 ? 'visible' : 'hidden'}} onClick={()=>removeGuest(i)}></button>
                    </div>
                  </div>
                ))
              }
            </div>
        <span className="bar mb30"></span>
              {guests.length < 15 &&
        <div className="buttons_center" style={{width: '100%'}}>
          <button type="button" className="btn-plus" onClick={()=>addGuest()}></button>
        </div>
        }
          </div>
          <div className='form_inner'>
              <div className="form_item mb40" style={{width:'100%'}}>
                <label htmlFor="currentBusinessIssues" className={`current_issues list_label ${focusedInput === 'currentBusinessIssues' ? 'focused' : ''}`}>
                <span class="tit-desc-p">*</span> 당사와의 관계 또는 연관된 비즈니스 현안 <i>(예- 독일 디스플레이 사업 당사 제1거래선)</i><br/>
                <span class="tit-desc-p">*</span> Business relations to Samsung or relevant business issues <i>(e.g. The 1st partner on display business in Germany)</i>
                </label>

                <div className={`${errors.currentBusinessIssues?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='text'
                    id={`currentBusinessIssues`}
                    name={`currentBusinessIssues`}
                    onFocus={() => handleFocus('currentBusinessIssues')}
                    onBlur={handleBlur}
                    {...register(`currentBusinessIssues`)}
                  placeholder='Please fill in the blank'
                    maxLength={255}
                  />
                  <div className='invalid-feedback'>{errors.currentBusinessIssues?.message}</div>
                </div>
              </div>
            </div>

            <div className="vip-reservation-tit">
            <h4>Additional accommodations</h4>
            <span className="tit-desc"></span>
          </div>
          
          <div className='form_inner mb60'>
            <div className='room-reservation-desc'>
            - 투어 중간에 계단을 이용하는 2층 코스가 포함되어 있으므로, 거동이 불편하시거나 별도의 도움이 필요하신 게스트가 있는 경우 기입<br/>
            - Please be aware that our booth has 2-floor access with stairs. If you have any guests in need of assistance, please enter the below.
            </div>
            <div className='rsvtour_items'>
              <div className="form_item mb40">
                <label htmlFor="isRequireMobilityAssistance" className={`list_label ${focusedInput === 'isRequireMobilityAssistance' ? 'focused' : ''}`}>Mobility assistance<span></span>이동 지원 서비스</label>
                  <select id={`isRequireMobilityAssistance`} name={`isRequireMobilityAssistance`} onFocus={() => handleFocus('isRequireMobilityAssistance')} onBlur={handleBlur} {...register(`isRequireMobilityAssistance`,{
                        onChange: (e)=>{
                          mobilityAssistanceReplace(e.target.value === 'true' ? {
                            information: '',
                            expectedService: '',
                            note: '',
                          } : [])
                          setValue('isRequireMobilityAssistance', e.target.value === 'true')                          
                        }})}>
                    <option value={false}>Not Required</option>
                    <option value={true}>Required</option>
                  </select>
              </div>
            </div>
            {        
              formData.isRequireMobilityAssistance && <>
                {
                 mobilityAssistances.map((item, i)=>( <> 
                  {i > 0 && <div className='assitance_added' key={i} style={{paddingTop: '50px'}}>
                      <button className="btn-remove" type="button" onClick={()=>removeMobilityAssistance(i)}></button>
                  </div>}
                  <div className='rsvtour_items'>
                    <div className="form_item mb40" style={{width:'100%'}}>
                      <label htmlFor={`mobilityAssistances[${i}]information`} className={`list_label ${focusedInput === `mobilityAssistances[${i}]information` ? 'focused' : ''}`}>Guest Information<span></span>게스트 정보 입력</label>
                      <div>
                        - 투어 그룹 현장 방문 시 지원 필요한 Guest 확인용<br/>
                        - To ensure the assistance of guests who will require accommodations for the tour.
                      </div>
                      <div className={`${errors.mobilityAssistances?.[i]?.information?.message ? 'is-invalid mr20' : 'mr20'}`}>
                      <input
                          type='text'
                          id={`mobilityAssistances[${i}]information`}
                          name={`mobilityAssistances[${i}]information`}
                          {...register(`mobilityAssistances.${i}.information`,{
                            onChange: (e)=>{
                              e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                          }})}
                          onFocus={() => handleFocus(`mobilityAssistances.${i}.information`)}
                          placeholder='Name of the guest'
                          onBlur={handleBlur}
                          maxLength={255}
                        />
                        <div className='invalid-feedback'>Required field</div>
                        </div>
                    </div>
                    <div className="form_item mb40" style={{width:'100%'}}>
                      <label htmlFor={`mobilityAssistances[${i}]expectedService`} className={`list_label ${focusedInput === `mobilityAssistances[${i}]information` ? 'focused' : ''}`}>Request for assistance<span></span>요청사항</label>
                      <div className={`${errors.mobilityAssistances?.[i]?.expectedService?.message ? 'is-invalid' : ''} mt10 checkbox`}>
                      <input type="checkbox" className='mr10' id={`mobilityAssistances[${i}]expectedService_1`} name={`mobilityAssistances[${i}]expectedService`} {...register(`mobilityAssistances.${i}.expectedService`)} value={'Hoist 승강기'}/><label htmlFor={`mobilityAssistances[${i}]expectedService_1`} className='mr30'>Hoist 승강기</label>
                      <input type="checkbox" className='mr10' id={`mobilityAssistances[${i}]expectedService_2`} name={`mobilityAssistances[${i}]expectedService`} {...register(`mobilityAssistances.${i}.expectedService`)} value={'Assistant 보조 인력'}/><label htmlFor={`mobilityAssistances[${i}]expectedService_2`} className='mr30'>Assistant 보조 인력</label>
                      <input type="checkbox" className='mr10' id={`mobilityAssistances[${i}]expectedService_3`} name={`mobilityAssistances[${i}]expectedService`} {...register(`mobilityAssistances.${i}.expectedService`)} value={'Just additional time 시간 여유'}/><label htmlFor={`mobilityAssistances[${i}]expectedService_3`} className='mr30'>Just additional time 시간 여유</label>
                      <input type="checkbox" className='mr10' id={`mobilityAssistances[${i}]expectedService_4`} name={`mobilityAssistances[${i}]expectedService`} {...register(`mobilityAssistances.${i}.expectedService`)} value={'Others 기타'}/><label htmlFor={`mobilityAssistances[${i}]expectedService_4`} className='mr30'>Others 기타</label>
                      <div className='invalid-feedback'>Required field</div>
                      </div>
                    </div>
                    <div className="form_item" style={{width:'100%'}}>
                      <label htmlFor={`mobilityAssistances[${i}]note`} className={`list_label mb20 ${focusedInput === `mobilityAssistances[${i}]note` ? 'focused' : ''}`}>Additional note or comment<span></span>비고</label>
                      <p><span className=''>- 게스트가 이전에 동일한 상황에서 제공받았던 서비스 등에 대한 기술</span> (e.g. 2명 이상의 보조 인력 필요)
                      <br/><span className=''>- Describe any accommodations guest typically received in the past for staircase access</span> (e.g. Assistance from more than one person)</p>
                      <input
                          type='text'
                          id={`mobilityAssistances[${i}]note`}
                          name={`mobilityAssistances[${i}]note`}
                          {...register(`mobilityAssistances.${i}.note`)}
                          onFocus={() => handleFocus(`mobilityAssistances.${i}.note`)}
                          onBlur={handleBlur}
                          placeholder='Optional'
                          maxLength={255}
                        />
                    </div>
                  </div>
                </>)) 
                }
                <div className="buttons_center" style={{margin: '30px auto 30px'}}>
                  <button type="button" className="btn-plus" onClick={()=>addMobilityAssistance()}></button>
                </div>
              </>
            }
          </div>

        <span className="bar mb60"></span>
        <div className="buttons_center">
          <Link to="/viptour/reservation-list" replace={true}><button type='button' className='btn-black mr20' style={{backgroundColor:'#000'}}><span className='spsans'>Cancel</span></button></Link>
          <button type="submit" className="btn-submit"><span className="spsans">Submit</span></button>
        </div>
      </form>
    </section>
    </>
  );
}