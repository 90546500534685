import history from '@/helpers/history';
import { useSelector } from 'react-redux';

function Footer() {
  const common = useSelector((state) => state.common);

  return (
    <>
      {
        !common.isLoading && (<footer className={`footer_wrap ${['/', '/ticket', '/viptour', '/room'].indexOf((history.location.pathname.toLowerCase() === '/' ? history.location.pathname.toLowerCase() : history.location.pathname.toLowerCase().replace(/\/+$/, ''))) > -1 ? 'home' : ''}`}>
        <small>Copyright 1995-2025 SAMSUNG. All rights reserved.</small>
        </footer>)
      } 
    </>
  )
}

export default Footer;
