const tourTime = [
  ...[7].flatMap(day => [
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '10:00 ~',
      label: '10:00 ~ 10:40'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '10:20 ~',
      label: '10:20 ~ 11:00'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '10:40 ~',
      label: '10:40 ~ 11:20'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '11:00 ~',
      label: '11:00 ~ 11:40'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '11:20 ~',
      label: '11:20 ~ 12:00'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '11:40 ~',
      label: '11:40 ~ 12:20'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '12:00 ~',
      label: '12:00 ~ 12:40'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '12:20 ~',
      label: '12:20 ~ 13:00'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '13:00 ~',
      label: '13:00 ~ 13:40'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '13:20 ~',
      label: '13:20 ~ 14:00'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '13:40 ~',
      label: '13:40 ~ 14:20'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '14:00 ~',
      label: '14:00 ~ 14:40'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '14:20 ~',
      label: '14:20 ~ 15:00'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '14:40 ~',
      label: '14:40 ~ 15:20'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '15:00 ~',
      label: '15:00 ~ 15:40'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '15:20 ~',
      label: '15:20 ~ 16:00'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '16:00 ~',
      label: '16:00 ~ 16:40'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '16:20 ~',
      label: '16:20 ~ 17:00'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '17:00 ~',
      label: '17:00 ~ 17:40'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '17:20 ~',
      label: '17:20 ~ 18:00'
    }
  ]),
  ...[8, 9].flatMap(day => [
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '09:00 ~',
      label: '09:00 ~ 09:40'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '09:20 ~',
      label: '09:20 ~ 10:00'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '10:00 ~',
      label: '10:00 ~ 10:40'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '10:20 ~',
      label: '10:20 ~ 11:00'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '10:40 ~',
      label: '10:40 ~ 11:20'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '11:00 ~',
      label: '11:00 ~ 11:40'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '11:20 ~',
      label: '11:20 ~ 12:00'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '11:40 ~',
      label: '11:40 ~ 12:20'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '12:00 ~',
      label: '12:00 ~ 12:40'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '12:20 ~',
      label: '12:20 ~ 13:00'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '13:00 ~',
      label: '13:00 ~ 13:40'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '13:20 ~',
      label: '13:20 ~ 14:00'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '13:40 ~',
      label: '13:40 ~ 14:20'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '14:00 ~',
      label: '14:00 ~ 14:40'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '14:20 ~',
      label: '14:20 ~ 15:00'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '14:40 ~',
      label: '14:40 ~ 15:20'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '15:00 ~',
      label: '15:00 ~ 15:40'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '15:20 ~',
      label: '15:20 ~ 16:00'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '16:00 ~',
      label: '16:00 ~ 16:40'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '16:20 ~',
      label: '16:20 ~ 17:00'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '17:00 ~',
      label: '17:00 ~ 17:40'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '17:20 ~',
      label: '17:20 ~ 18:00'
    }
  ]),
  ...[10].flatMap(day => [
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '09:00 ~',
      label: '09:00 ~ 09:40'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '09:20 ~',
      label: '09:20 ~ 10:00'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '10:00 ~',
      label: '10:00 ~ 10:40'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '10:20 ~',
      label: '10:20 ~ 11:00'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '10:40 ~',
      label: '10:40 ~ 11:20'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '11:00 ~',
      label: '11:00 ~ 11:40'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '11:20 ~',
      label: '11:20 ~ 12:00'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '11:40 ~',
      label: '11:40 ~ 12:20'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '12:00 ~',
      label: '12:00 ~ 12:40'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '12:20 ~',
      label: '12:20 ~ 13:00'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '13:00 ~',
      label: '13:00 ~ 13:40'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '13:20 ~',
      label: '13:20 ~ 14:00'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '13:40 ~',
      label: '13:40 ~ 14:20'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '14:00 ~',
      label: '14:00 ~ 14:40'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '14:20 ~',
      label: '14:20 ~ 15:00'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '14:40 ~',
      label: '14:40 ~ 15:20'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '15:00 ~',
      label: '15:00 ~ 15:40'
    },
    {
      date: `2025-01-${String(day).padStart(2, '0')}`,
      time: '15:20 ~',
      label: '15:20 ~ 16:00'
    }
  ])
];

export default tourTime;
